
<template>
<div>
    <!-- Busqueda SalesOrder -->
    <v-row>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="purchases.length > 0">
      <span class="right"><b>{{ purchases.length }}</b> registro(s) encontrados <br></span>
      <br><br>
        <!-- Tabla Salida -->
        <template>
          <!-- height="700px" -->
          <v-simple-table
            fixed-header
            dense
          >
            <template v-slot:default>
              <thead>
                <tr><th class="text-left" v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>
              </thead>
              <tbody>
                <!-- v-for="row in subfamilies" -->
                <tr
                  v-for="(row,index) of purchases"
                  :key="row.id"
                >
                <td>
                  <!-- 
                  <button v-on:click="deleteFriend(row.id, i)">x</button>
                  -->
                  <v-btn v-on:click="editRow = row.id" icon>
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <a v-bind:href="url(row.id)">
                    <v-icon>visibility</v-icon>
                  </a>                
                </td>  
                <td> {{index+1}}  </td>
                <td> 
                  {{row.id}}  
                  <div v-if="editRow === row.id">
                    <!-- <input v-on:keyup.13="updateRow(row)" placeholder="Sucursal id" v-model="row.branch_id" /> -->                    
                    <v-autocomplete
                              v-model="row.supplier_id"
                              :hint="`${suppliers.id} - ${suppliers.tipo_cambio} ${suppliers.abreviada}`"
                              :items="suppliers"
                              item-text="razon_social"
                              item-value="id"
                              label="Selecciona un Proveedor*"
                              outlined
                    ></v-autocomplete>            
                    <v-autocomplete
                              v-model="row.currency_id"
                              :hint="`${currencies.id} - ${currencies.name} ${currencies.tipo_cambio}`"
                              :items="currencies"
                              item-text="tipo_cambio"
                              item-value="id"
                              label="Selecciona un Tipo de Cambio*"
                              outlined
                    ></v-autocomplete>
                    <v-col cols=12 sm=12 xs=12 md=4 lg=4>
                      <v-switch
                        v-model="row.iva"
                        label="IVA"
                      ></v-switch>             
                    </v-col>           
                    <v-text-field
                                v-model="row.num_pedimento"
                                v-on:keyup.13="updateRow(row)"
                                label="Número Pedimento"
                    /> 
                    <v-text-field
                                v-model="row.discount"
                                v-on:keyup.13="updateRow(row)"
                                label="Descuento %"
                    /> 
                    <v-text-field
                                v-model="row.aduana_cost"
                                v-on:keyup.13="updateRow(row)"
                                label="Costo Aduana"
                    /> 
                    <v-text-field
                                v-model="row.comision"
                                v-on:keyup.13="updateRow(row)"
                                label="Comision %"
                    /> 
                    <v-text-field
                                v-model="row.shipping_cost"
                                v-on:keyup.13="updateRow(row)"
                                label="Costo Envío"
                    /> 
                    <v-text-field
                                v-model="row.other_cost"
                                v-on:keyup.13="updateRow(row)"
                                label="Otro Costo"
                    /> 
                                        
                    <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
                    <v-btn v-on:click="editRow=false" class="deep-dark">Cerrar</v-btn>
                  </div>

                </td>
                <td>
                  <div v-if="row.project_id!=null">{{row.projectfolio }} / {{row.project_id }} </div>  
                  <div v-else>
                        <v-chip
                          class="ma-2"
                          color="deep-orange"
                          label
                          text-color="white"
                          >
                            <v-icon left>
                              mdi-label
                            </v-icon>
                            Sin Folio
                          </v-chip>
                  </div>  
                    
                  <v-btn v-on:click="editRowProjectID = row.id" icon>
                        <v-icon>edit</v-icon>
                  </v-btn>
                  <div v-if="editRowProjectID === row.id">
                      <v-text-field
                                    class="my-5 py-0"
                                    v-model="row.project_id"    
                                    v-on:keyup.13="updateRowProject(row)"
                                    label="Folio Proyecto"
                                    placeholder="Escribe un folio"
                                    rounded
                                    outlined
                      ></v-text-field>
                      <v-btn v-on:click="updateRowProject(row)" class="green">Actualizar</v-btn>
                      <v-btn v-on:click="editRowProjectID=false" class="deep-black">Cerrar</v-btn>
                  </div>       
                </td>
                <td>
                  {{row.suppliername}} <br>
                  <small>{{row.branchname}}</small><br>
                  <span style="color:green; padding:0px; margin:0px;">{{row.title}}</span>                     
                </td>
                <td>{{row.num_pedimento}}  </td>                    
                <td>{{row.shipping_cost | currency }} </td>
                <td>{{row.other_cost | currency }} </td>
                <td>{{row.aduana_cost | currency }} </td>
                <td>{{row.comision}}</td>
                <td>{{row.currencytipocambio | currency }} <small>{{row.currencyabraviada}}</small></td>      
                <td>{{row.costproducts | currency }} </td>
                <td>{{row.purchaseiva | currency }} </td>
                <td><span style="font-weight:bold; font-size:1.3rem">{{row.totalpurchase | currency }}</span></td>      
                <td>{{formatDate(row.date_buy)}}</td>     
                <td>{{formatDate(row.created_at)}}</td>     
                <td>{{formatDate(row.updated_at)}}</td>  
                
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <!-- FIN table -->                                    
    </v-col>
    <v-col cols=12 sm=12 md=12 lg=12
      v-else
    >
      <v-alert
          dense
          outlined
          type="error"
      >
          No hay registros encontrados
      </v-alert>
    </v-col>
  </v-row>
  <!-- FIN Busqueda SalesOrder -->  

  <!-- DIALOG NOTAS -->
<div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <!-- FIN DIALOG NOTAS -->

</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
// import{ mapMutations,mapState,mapGetters } from "vuex";
import baseApi from '@/services/baseApi';
// import router from '@/router';
import Dayjs from 'dayjs';
import es from "dayjs/locale/es"




export default {
  props:{
    purchases:{
      type:Object,
      required:true
    }
  },
  computed:{
    //'products'
      ...mapState(['suppliers','currencies','currentUser'])
  },
  components: {    

  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]), 
    formatDate(dateString) {
            Dayjs.locale(es)
            const date = Dayjs(dateString);
            return date.format('dddd MMMM D, YYYY')
    },
    async getData(){   
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        // this.$store.dispatch('loadCustomers',this.attribute);
        this.$store.dispatch('loadCurrencies',this.attribute);
        this.$store.dispatch('loadSuppliers',this.attribute);
      }catch(error){
        //console.log(error);
        this.$swal("Error!", "Necesita ingresar sus credenciales", "error");
      } finally{
        this.hiddenLoading()
      } 
    },
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    },  
    sendMail(id){      
      try{        
        this.showLoading({titulo:"Accediendo información",color:"warning"})    
        if(confirm("Desea enviar el correo de la venta: "+ id+"?")){            
          baseApi().get(`https://www.cloudbits.org.mx/fw/lumen/check/email/detalleventa/${id}?branch_id=1`).then((response)=>{
              if(!response.data.error){                            
                if(response.data.error==false)                            
                  this.$swal("Genial!",response.data.message, "success");
                else
                  this.$swal("Error!",response.data.message, "error");  
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("VENTA: "+response.data.message);
          });         
        }
      }catch(error){
        console.log(error);        
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    },
    url: function (href){
      return 'detallecompra?purchaseid='+ href
    }, 
    urlCustomer: function (href){
        return 'detalleventascliente?customerid='+ href
    },
    updateRow(row) {  
        // alert(row.iva)    
        try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                   
          baseApi().post(`/compras/update/${row.id}`,JSON.stringify(row)).then((response)=>{
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              console.log("COMPRAS: "+response.data.message);
          });                                                                                                   
          // console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
    updateRowProject(row){
      try{        
        this.showLoading({titulo:"Accediendo información",color:"warning"})                
        baseApi().post(`/compras/updateFolio/${row.project_id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              // this.$swal("Error!",response.data.message, "error");
              this.$swal("Error!",response.data.message+': <br/>'+JSON.stringify(response.data.validate), "error"); 
            }                                    
            console.log("VENTA: "+response.data.message);
        });         
      }catch(error){
        console.log(error);        
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    }
    
  },
  destroyed() {
    console.log("Destruido PurchaseItem");
  },
  mounted(){
    console.log("Montado PurchaseItem");
    // if (this.productid>0) {
      this.getData()
      
    // }
  },
  data(){
    return{
      attribute: {sword: '',limit: 500},
      dialogNotes:false,
      editRow:null,
      editRowProjectID:null,
      customer_id:77,
      itemsStatus: [
          'Completado',
          // 'Facturado',
          'Proceso'
      ],  
      notes:'',
   
      theader: [
          { text: 'Accion', value: 'customername' },
          { text: '#', value: 'customername' },
          { text: 'ID', value: 'customername' },
          { text: 'Folio Proyecto', value: 'customername' },
          { text: 'Proveedor', value: 'customername' },
          { text: 'Num. Pedimento', value: 'customername' },          
          { text: 'Costo Envio', value: 'customername' },          
          { text: 'Otro Costo', value: 'customername' },          
          { text: 'Costo Aduana', value: 'customername' },          
          { text: 'Comision', value: 'customername' },          
          { text: 'Tipo de Cambio', value: 'customername' },          
          { text: 'Subtotal', value: 'subtotal_quote' },
          // { text: 'Descuento', value: 'subtotal_quote' },
          // { text: 'Subtotal c/Desc', value: 'subtotal_quote' },
          { text: 'IVA', value: 'iva_quote' },
          { text: 'Total', value: 'total_quote ' },       
          // { text: 'Status', value: 'total_quote ' },       
          { text: 'Compra', value: 'iva_quote' },
          { text: 'Registro', value: 'iva_quote' },
          { text: 'Actualizacion', value: 'iva_quote' },
      ],
    }
  }
}
</script>